import axios, { AxiosRequestConfig } from "axios";
// config
import { BASE_URL } from "src/config-global";

const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, {
    ...config,
  });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  AUTH: {
    LOGIN: "/v1/auth/login",
    REGISTER: "/api/auth/register",
    RESET_PASSWORD: "/v1/auth/reset-password?token={token}",
    GET_ALL_CLIENTS: "/v1/users/clients-for-clients/",
    GET_CSM_CLIENTS: "/v1/users/clients/",
    GET_CLIENT_TOKEN: "/v1/auth/change-token-for-client/",
  },
  UTILITY: {
    OPTIONS: {
      CHANNELS: "/v1/options/channels",
      LOCATIONS: "/v1/options/locations",
      GET_DISCOUNT_TYPE: "/v1/options/campaign-type",
      CITIES: (aggregator?: string) =>
        `/v1/market-intelligence${aggregator ? `/${aggregator}` : ""}/cities`,
      AREAS: "/v1/market-intelligence/areas",
      CUISINES: (aggregator?: string) =>
        `/v1/market-intelligence${aggregator ? `/${aggregator}` : ""}/cuisines`,
    },
  },
  BUDGET: {
    GET: "/v1/brand-budget/brandId",
    POST: "/v1/brand-budget/{brandId}",
    GET_DETAILS: "/v1/brand-budget/{brandId}/budget/{budgetId}",
    UPDATE: "/v1/brand-budget/{brandId}/budget/{budgetId}",
    DELETE: "/v1/brand-budget/{brandId}/budget/{budgetId}",
    GET_BUDGET_CATEGORY: "/v1/budget-category",
  },
  DISCOUNT: {
    GET: "/v1/discount",
    GET_BRANCH_LEVEL_DISCOUNTS: "/v1/discount/by-branch",
    GET_CAMPAIGN_LEVEL_DISCOUNTS: "/v1/discount/by-campaign",
    GET_BRANDS: "/v1/discount/brands",
    GET_BRANCHES: "/v1/discount/branches",
    CREATE_CAMPAIGN: "/v1/discount/discount-campaigns",
    GET_BRANCH_PERFORMANCE: "/v1/discount/branch-performance-with-orders",
  },
  RULE_BASED_DISCOUNT: {
    GET: "/v1/discount-rules/list",
    GET_DETAILS: "/v1/discount-rules/{ruleId}",
    CREATE_CAMPAIGN: "/v1/discount-rules",
    CANCEL_CAMPAIGN: "/v1/discount-rules/{ruleId}",
    GET_DETAIL_CAMPAIGN: "/v1/discount-rules/{ruleId}/campaigns",
    GET_BRANDS: "/v1/discount-rules/brands",
    GET_BRANCHES: "/v1/discount-rules/branches",
  },
  ADS: {
    GET: "/v1/ad-campaign",
    GET_DETAILS_STATS: "/v1/ad-campaign/{unique_identifier}",
    GET_DETAILS_GRAPHS: "/v1/ad-campaign/{id}/graphs",
    GET_DETAILS: "/v1/ad-campaign/details",
    ADD_CPC_DELIVEROO: "/v1/ad-campaign/deliveroo",
    ADD_CPC_TALABAT: "/v1/ad-campaign/talabat",
    ADD_CPC_HUNGERSTATION: "/v1/ad-campaign/hungerstation",
    ADD_CPC_NOON: "/v1/ad-campaign/noon",
    GET_CPC_LIMIT: "/v1/ad-campaign/cpc-limits/{channel}",
    GET_BRANCH_PERFORMANCE_ORDER:
      "/v1/ad-campaign/branch-performance-with-orders",
    GET_TALABAT_PERFORMANCE_AREA: "/v1/ad-campaign/talabat/area-performance",
    GET_ADS_PERFORMANCE_AREA: "/v1/ad-campaign/talabat/ads-area-performance",
  },
  CALENDAR: {
    ADD_CAMPAIGN: "/v1/brand-campaign/{brandId}",
    GET_ALL_CAMPAIGN: "/v1/brand-campaign",
    GET_CAMPAIGN_DETAILS: "/v1/brand-campaign/{brandId}/campaign/{id}",
    EDIT_CAMPAIGN: "/v1/brand-campaign/{brandId}/campaign/{id}",
    DELETE_CAMPAIGN: "/v1/brand-campaign/{brandId}/campaign/{id}",
    CAMPAIGN_SUMMARY: "/v1/brand-campaign/summary",
  },
  MARKETINGOVERVIEW: {
    GET_OVERVIEW_SUMMARY: "/v1/marketing",
    GET_OVERVIEW_PERFORMANCE: "/v1/marketing/details",
  },
  MANAGEMENT: {
    ADD_USER: "/v1/users/invite/",
    GET_USERS: "/v1/users/me",
    DELETE_CLIENT: "/v1/users/{id}",
    CREATE_COMMISSIONS: "/v1/client/commissions",
    GET_COMMISSIONS: "/v1/client/commissions",
    UPDATE_COMMISSION: "/v1/client/commissions/{id}/update",
    DELETE_COMMISSION: "/v1/client/commissions/{id}",
    GET_FOOD_COST: "/v1/client/foods/",
    UPDATE_FOOD_COST: "/v1/client/foods/{id}",
    OTHER_COST: "/v1/client/other-cost",
    OTHER_COST_DETAILS: "/v1/client/other-cost/{id}",
    EMAIL: {
      SUBSCRIBE: "v1/users/notifications/email/subscribe/{id}",
      UNSUBSCRIBE: "v1/users/notifications/email/unsubscribe/{id}",
    },
  },
  CAMPAIGN: {
    GET: "/v1/ad-campaign/{unique_identifier}/cpc-edit-data",
    UPDATE: "/v1/ad-campaign/{unique_identifier}",
    CALCEL_DELIVEROO_CAMPAIGN:
      "/v1/ad-campaign/{unique_identifier}/cancel-cpc-campaign",
  },
  FINANCE: {
    GET: "/v1/finance/summary",
    GET_REPORT: "/v1/finance/report",
  },
  REVIEWS: {
    GET_DETAILS: "/v2/rating/by-branch",
    GET_SUMMARY: "/v2/rating/summary",
    NOTIFY_ME: "v1/admin-logs/notify-reviews",
    NOTIFY_GROW_INTEL: "/v1/admin-logs/notify-grow-intel",
  },
  BRANCH: {
    GET_BRANCHES: "/v1/branches",
    GET_TALABAT_BRANCHES: "/v1/aggregators/talabat/branches",
    GET_DELIVEROO_BRANCHES: "/v1/aggregators/deliveroo/branches",
    GET_HUNGERSTATION_BRANCHES: "/v1/aggregators/hungerstation/branches",
    GET_BRANCH_AREAS: "/v1/branches/{branch_id}/areas",
    GET_TALABAT_AREAS: "/v1/aggregators/talabat/{branch_id}/areas",
    GET_NOON_BRANCHES: "/v1/aggregators/noon/branches",
    GET_HUNGERSTATION_AREAS: "/v1/aggregators/hungerstation/{branch_id}/areas",
  },
  OPERATIONS: {
    GET_SUMMARY: "/v1/operations/summary",
    GET_OPERATION_BYBRANCH: "/v1/operations/by-branch",
  },
  OVERVIEW: {
    GET_SUMMARY: "/v1/overview/summary",
    GET_BY_BRANCH: "/v1/overview/by-branch",
    GET_BY_BRAND: "/v1/overview/by-brand",
  },
  SUBSCRIPTION: {
    status: "/v1/subscription/status",
    portal: "/v1/subscription/portal",
    creditnotes: "/v1/subscription/credit-notes",
  },
  GROW_INTEL: {
    GET_COMPETITORS: "/v1/market-intelligence/competitors",
    POPULAR_BRANDS: "/v1/market-intelligence/popular-brands",
    POPULAR_ITEMS: "/v1/market-intelligence/popular-items",
    MARKET_CHANGES: "/v1/market-intelligence/market-changes",
    ITEM_SEARCH: "/v1/market-intelligence/item-search",
    SEARCH_BRANCH: "/v1/market-intelligence/search_branch",
    GET_BRANCH: (branchId: string) =>
      `/v1/market-intelligence/restaurant?vendor_id=${branchId}`,
    MENU_SUGGESTION: "/v1/market-intelligence/menu-suggestion",
    MENU_ANALYSIS: "/v1/market-intelligence/menu-analysis",
  },
  GROW_ROMANIA: {
    GET_COMPETITORS: "/v1/market-intelligence/glovo/competitors",
    POPULAR_BRANDS: "/v1/market-intelligence/glovo/popular-brands",
    POPULAR_ITEMS: "/v1/market-intelligence/glovo/popular-items",
    MARKET_CHANGES: "/v1/market-intelligence/glovo/market-changes",
    ITEM_SEARCH: "/v1/market-intelligence/glovo/item-search",
    SEARCH_BRANCH: "/v1/market-intelligence/glovo/search-branch",
    GET_BRANCH: (branchId: string) =>
      `/v1/market-intelligence/glovo/restaurant?restaurant_id=${branchId}`,
    MENU_SUGGESTION: "/v1/market-intelligence/glovo/menu-suggestion",
    MENU_ANALYSIS: "/v1/market-intelligence/glovo/menu-analysis",
  },
};
